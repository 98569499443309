@import "./main.scss";

.heroImage {
	background-image: url(./Pictures/editedhero.png);
	background-size: cover;
	height: 60rem;
	width: 100vw;
	object-fit: contain;
	@media only screen and (max-width: 500px) {
		height: 25rem;
	}
	
}

.slogan {
	text-align: center; 
	font-style: italic;
	color: #454141;
	font-size: 3.5rem;
	font-family: "Josefin Sans", sans-serif;
	margin-top: 4rem;
	margin-bottom: 2rem;
	@media only screen and (max-width: 500px) {
		font-size: 2.8rem;
		margin-top: 2rem;
	}
}

.btn-wrapper {
	display: flex;
	justify-content: center;
	margin-top: -100px;
}

.btn-store {
	background-color: black;
	color: #fff;
	border: none;
	border-radius: 0;
	font-family: "Josefin Sans", sans-serif;
	font-size: 1.5rem;
	font-weight: 00;
	text-transform: uppercase;
	padding: 1.8rem 3rem;
	cursor: pointer;
	transition: all 0.2s;
	margin-top: 15rem;
	margin-bottom: 10rem;
	
	@media only screen and (min-height: 800px) {
	
		margin-bottom: 18rem;
		transform: translateY(26px);
	}
	
	@media only screen and (max-height: 780px) {
		transform: translateY(15px);
	}
	
	// &:hover {
	// 	box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
	// 		0 17px 50px 0 rgba(0, 0, 0, 0.19);

	// }
	&:focus,
	:active {
		border: none;
		border-radius: 0;
	}
}

.footer {
	z-index: -1; 
	height: 100%;
	bottom: 0;
	margin-bottom: 0;
	margin-top: auto;
	display: flex;
	background-color: black;
	padding-top: 1rem;
	padding-left: 1.5rem;
	&__name {
		font-family: 'Norican', cursive;
		color: #D5DBDB;
		align-self: center;
		font-size: 3rem;
		@media only screen and (max-width: 350px) {
		font-size: 2.5rem;
	}
	}
}

.contact {
	align-content: flex-start;
	display: flex;
	flex-direction: column;
	margin-left: auto;
	padding-right: 3rem;
	&__part {
		display: flex;
		align-self: start;
		margin-bottom: .5rem;
		&-att {
			text-decoration: none;
			color: #D0D3D4;
			font-family: "Nunito Sans", sans-serif;
			font-size: 1.5rem;
			margin-left: 1rem;
			align-self: center;
			@media only screen and (max-width: 350px) {
			font-size: 1.25rem; 
			}
			&:hover {
				color: #fff;
			}
		}
	}
  	
}

.headeralt {
	font-family: 'Norican', cursive;
	font-size: 4rem;
	display: flex;
	justify-content: center;
	color: #D5DBDB;
	background-color: black;
}

