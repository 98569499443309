//my bp
$bp-largest: 68.75em; //1100px
$bp-large: 66.25em; //1060px
$bp-medium: 50em; //800px
$bp-medium2: 54.4375em; //871px
$bp-tab: 683px;

$color-grey-light-1: #f9f7f6;
$color-grey-light-2: #d3d3d3;
$color-grey-light-3: #aaa;

*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

html {
	box-sizing: border-box;
	font-size: 62.5%; //1rem is 10px here

	@media only screen and (max-width: 800px) {
		font-size: 50%; //1rem becomes 8px here
	}

	@media only screen and (max-width: 66.25em) {
		font-size: 56.25%;
	}

	@media only screen and (max-width: $bp-medium) {
		font-size: 40%;
	}
}

body {
	overflow-y: auto;
	overflow-x: hidden;
	height: 100%;
	font-weight: 300;
	line-height: 1.6;
}
