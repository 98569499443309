.slider {
	font-family: sans-serif;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 60rem;
	width: 100vw;
	margin-top: -2rem;
	
}

.rec-carousel-item:focus {
	outline: none;
	box-shadow: inset 0 0 1px 0px violet;
}

.rec.rec-arrow {
	color: black;
	border: none !important;
	background-color: #f9f7f6;
	cursor: pointer;

}

button.rec.rec-arrow {
	background-color: black;
	color: white;
}

button.rec.rec-arrow:hover {
	background-color: black;
}

button.rec.rec-arrow:focus,
active {
	background-color: black;
}

.rec.rec-arrow:disabled {
	visibility: hidden;
}

.rec.rec-arrow-right {
	margin-right: 3rem;
	@media only screen and (max-width: 66.25em) {
		margin-right: 12rem; 
	}
	@media only screen and (max-width: 66.25em) {
		margin-top: 40px;
	}
}

.rec.rec-dot_active {
	background-color: rgba(0, 0, 0, 0.5);
	color: rgba(0, 0, 0, 0.5);
}

.rec.rec-arrow-left {
	margin-left: 30px;
}

.slider-img {
	object-fit: cover;
	height: inherit;
}
